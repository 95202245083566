export enum BooleanOperator {
  AND = "\u2227",
  CONTRADICTION = "\u22A5",
  EQUIVALENCE = "\u2194",
  IMPLICATION_LR = "\u2192",
  IMPLICATION_RL = "\u2190",
  NAND = "\u22BC",
  NOR = "\u22BD",
  NOT = "\u00AC",
  OR = "\u2228",
  TAUTOLOGY = "\u22A4",
  XOR = "\u22BB"
}

export const SymbolsToNames = {
  "!": "not",
  "*": "xor",
  "#": "nand",
  "/": "nor",
  "&": "and",
  "|": "or",
  ">": "imp",
  "<": "pmi", // converse implies
  "=": "equ"
};

export const OperatorsToNames = {
  [BooleanOperator.NOT]: "not",
  [BooleanOperator.XOR]: "xor",
  [BooleanOperator.NAND]: "nand",
  [BooleanOperator.NOR]: "nor",
  [BooleanOperator.AND]: "and",
  [BooleanOperator.OR]: "or",
  [BooleanOperator.IMPLICATION_LR]: "imp",
  [BooleanOperator.IMPLICATION_RL]: "pmi",
  [BooleanOperator.EQUIVALENCE]: "equ"
};

export const NamesToOperators = {
  not: BooleanOperator.NOT,
  xor: BooleanOperator.XOR,
  nand: BooleanOperator.NAND,
  nor: BooleanOperator.NOR,
  and: BooleanOperator.AND,
  or: BooleanOperator.OR,
  imp: BooleanOperator.IMPLICATION_LR,
  pmi: BooleanOperator.IMPLICATION_RL,
  equ: BooleanOperator.EQUIVALENCE,
  true: "1",
  false: "0",
};

export const SymbolsToOperators = {
  "!": BooleanOperator.NOT,
  "*": BooleanOperator.XOR,
  "#": BooleanOperator.NAND,
  "/": BooleanOperator.NOR,
  "&": BooleanOperator.AND,
  "|": BooleanOperator.OR,
  ">": BooleanOperator.IMPLICATION_LR,
  "<": BooleanOperator.IMPLICATION_RL,
  "=": BooleanOperator.EQUIVALENCE
};

export const OperatorsToSymbols = {
  [BooleanOperator.NOT]: "!",
  [BooleanOperator.XOR]: "*",
  [BooleanOperator.NAND]: "#",
  [BooleanOperator.NOR]: "/",
  [BooleanOperator.AND]: "&",
  [BooleanOperator.OR]: "|",
  [BooleanOperator.IMPLICATION_LR]: ">",
  [BooleanOperator.IMPLICATION_RL]: "<",
  [BooleanOperator.EQUIVALENCE]: "="
};

export enum BooleanOperatorOrder {
  NOT = 9, // '!'
  XOR = 4, // '*'
  NAND = 7, // '#'
  NOR = 5, // '/'
  AND = 8, // '&'
  OR = 6, // '|'
  IMPLICATION_LR = 3, // '>'
  IMPLICATION_RL = 2, // '<'
  EQUIVALENCE = 1 // '='
}

export const AllowedOperators = {
  "!": BooleanOperatorOrder.NOT,
  "*": BooleanOperatorOrder.XOR,
  "#": BooleanOperatorOrder.NAND,
  "/": BooleanOperatorOrder.NOR,
  "&": BooleanOperatorOrder.AND,
  "|": BooleanOperatorOrder.OR,
  ">": BooleanOperatorOrder.IMPLICATION_LR,
  "<": BooleanOperatorOrder.IMPLICATION_RL,
  "=": BooleanOperatorOrder.EQUIVALENCE
};

export enum BooleanOperatorComparison {
  "!" = BooleanOperator.NOT,
  "*" = BooleanOperator.XOR,
  "#" = BooleanOperator.NAND,
  "/" = BooleanOperator.NOR,
  "&" = BooleanOperator.AND,
  "|" = BooleanOperator.OR,
  ">" = BooleanOperator.IMPLICATION_LR,
  "<" = BooleanOperator.IMPLICATION_RL,
  "=" = BooleanOperator.EQUIVALENCE
}
